import { firstValueFrom, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Branch, IQueryResponse, Query } from '@supy/common';

import { Channel } from '../core';
import { ChannelsService } from '../services';

@Injectable()
export class BranchChannelsResolver {
  constructor(private readonly channelsService: ChannelsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): IQueryResponse<Channel> | Observable<IQueryResponse<Channel>> | Promise<IQueryResponse<Channel>> {
    const branch = route.parent.data.branch as Branch;
    const query = new Query<Channel>({
      paging: { offset: 0, limit: 40 },
      filtering: [{ by: 'retailerId', op: 'eq', match: branch.id }],
    });

    return firstValueFrom(this.channelsService.getChannels(query));
  }
}
