import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SupyCommonModule } from '@supy/common';
import {
  AvatarModule,
  BadgeModule,
  ButtonModule,
  ComboboxModule,
  DropdownModule,
  IconModule,
  ListModule,
  LoadingOverlayModule,
  SearchInputModule,
  SelectModule,
  TextareaModule,
} from '@supy/components';

import {
  ChannelsHeaderComponent,
  ChannelsListComponent,
  ChannelUsersComponent,
  ItemMessageComponent,
  MessageComponent,
  MessagesFooterComponent,
  MessagesHeaderComponent,
  TextMessageComponent,
} from './components';

const COMPONENTS = [
  ChannelsHeaderComponent,
  ChannelsListComponent,
  ChannelUsersComponent,
  MessagesFooterComponent,
  MessagesHeaderComponent,
  MessageComponent,
  TextMessageComponent,
  ItemMessageComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SupyCommonModule,
    SelectModule,
    ListModule,
    BadgeModule,
    AvatarModule,
    RouterModule,
    ButtonModule,
    IconModule,
    TextareaModule,
    ReactiveFormsModule,
    DropdownModule,
    LoadingOverlayModule,
    SearchInputModule,
    ComboboxModule,
  ],
  exports: [...COMPONENTS],
})
export class SharedChannelsModule {}
