import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Query, QueryPaging } from '@supy/common';

import { Channel } from '../core';
import { ChannelsService } from '../services';

@Injectable()
export class SupplierChannelsResolver {
  constructor(private readonly channelsService: ChannelsService) {}

  resolve(route: ActivatedRouteSnapshot): Channel[] | Observable<Channel[]> | Promise<Channel[]> {
    const query = new Query<Channel>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'supplierId', op: 'eq', match: route.params.id as string }],
    });

    return this.channelsService.getChannels(query).pipe(map(({ data }) => data));
  }
}
