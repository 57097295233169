import { map, Observable, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';

import { Query, QueryPaging } from '@supy/common';
import { CurrentUserState, GetCurrentUser } from '@supy/users';

import { Channel } from '../core';
import { ChannelsService } from '../services';

/**
 * @deprecated use UserRetailerBranchesChannelsResolver instead
 */
@Injectable({
  providedIn: 'root',
})
export class UserChannelsResolver {
  constructor(
    private readonly channelsService: ChannelsService,
    private readonly store: Store,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Channel[]> {
    return this.store.dispatch(new GetCurrentUser()).pipe(
      map(() => this.store.selectSnapshot(CurrentUserState.getCurrentUser)),
      switchMap(user => {
        const query = new Query<Channel>({
          paging: QueryPaging.NoLimit,
          filtering: [{ by: 'users', op: 'contains', match: user?.id }],
        });

        return this.channelsService.getChannels(query).pipe(map(({ data }) => data));
      }),
    );
  }
}
