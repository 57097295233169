import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseHttpService, IQueryResponse, Query, QueryPaging, User, UserState } from '@supy/common';
import { UsersService } from '@supy/users';

import {
  Channel,
  ChannelBase,
  ChannelExternalsRequest,
  ChannelId,
  ChannelMetadataRequest,
  ChannelUsersRequest,
  CreateChannelRequest,
  CreateChannelResponse,
  PopulatedChannel,
} from '../core';
import { CHANNELS_URI } from './../config';

@Injectable({ providedIn: 'root' })
export class ChannelsService extends BaseHttpService {
  readonly channelChange = new BehaviorSubject<string>(null);
  channelChanged$ = this.channelChange.asObservable();

  constructor(
    protected readonly httpClient: HttpClient,
    private readonly usersService: UsersService,
    @Inject(CHANNELS_URI) protected readonly uri: string,
  ) {
    super(uri);
  }

  createChannel(body: CreateChannelRequest): Observable<CreateChannelResponse> {
    return this.post<CreateChannelRequest, CreateChannelResponse>(body);
  }

  getChannels(query: Query<Channel>): Observable<IQueryResponse<Channel>> {
    return this.get<IQueryResponse<Channel>>('', query.toQueryParams());
  }

  getPopulatedChannels(query: Query<Channel>): Observable<IQueryResponse<PopulatedChannel>> {
    return this.getChannels(query).pipe(
      switchMap(channels => {
        if (!channels.data.length) {
          return of(channels as IQueryResponse<ChannelBase> as IQueryResponse<PopulatedChannel>);
        }

        const allUsersIds = new Set<string>(channels.data.flatMap(({ users }) => users));

        const query = new Query<User>({
          paging: QueryPaging.NoLimit,
          filtering: [
            { by: 'id', op: 'in', match: Array.from(allUsersIds) },
            { by: 'state', op: 'eq', match: UserState.active },
          ],
        });

        return this.usersService.getUsers(query).pipe(
          map(users => new Map<string, User>(users.data.map(user => [user.id, user]))),
          map(usersMap => {
            const data: PopulatedChannel[] = channels.data.map(channel => ({
              ...channel,
              users: channel.users.reduce<User[]>((acc, cur) => {
                if (usersMap.has(cur)) {
                  acc.push(usersMap.get(cur));
                }

                return acc;
              }, []),
            }));

            return { data, metadata: channels.metadata };
          }),
        );
      }),
    );
  }

  getChannel(channelId: string): Observable<Channel> {
    return this.get<Channel>(channelId);
  }

  addChannelUsers(body: ChannelUsersRequest, channelId: string): Observable<void> {
    return this.patch<ChannelUsersRequest, void>(body, `${channelId}/users/add`);
  }

  removeChannelUsers(body: ChannelUsersRequest, channelId: string): Observable<void> {
    return this.patch<ChannelUsersRequest, void>(body, `${channelId}/users/remove`);
  }

  manageChannelUsers(body: ChannelUsersRequest, channelId: string, shouldAdd: boolean): Observable<void> {
    if (shouldAdd) {
      return this.addChannelUsers(body, channelId);
    }

    return this.removeChannelUsers(body, channelId);
  }

  removeChannelExternals(body: ChannelExternalsRequest, channelId: string): Observable<void> {
    return this.patch<ChannelExternalsRequest, void>(body, `${channelId}/externals/remove`);
  }

  patchChannelMetadata(body: ChannelMetadataRequest, channelId: ChannelId): Observable<void> {
    return this.patch<ChannelMetadataRequest, void>(body, `${channelId}/metadata`);
  }

  notifyChannelChange(channelId: string) {
    this.channelChange.next(channelId);
  }
}
