import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Channel } from '../core';
import { ChannelsService } from '../services';

@Injectable()
export class ChannelResolver {
  constructor(private readonly channelsService: ChannelsService) {}

  resolve(route: ActivatedRouteSnapshot): Channel | Observable<Channel> | Promise<Channel> {
    return this.channelsService.getChannel(route.paramMap.get('channelId'));
  }
}
