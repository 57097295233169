import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule, DialogModule } from '@supy/components';
import { UsersModule } from '@supy/users';

import { EditIngredientComponent } from './components/edit-ingredient';
import { BranchChannelsResolver, ChannelResolver, SupplierChannelsResolver, UserChannelsResolver } from './resolvers';

@NgModule({
  declarations: [EditIngredientComponent],
  imports: [CommonModule, UsersModule, DialogModule, ButtonModule],
  providers: [BranchChannelsResolver, SupplierChannelsResolver, ChannelResolver, UserChannelsResolver],
})
export class ChannelsModule {}
