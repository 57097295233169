import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IgxGridModule } from '@infragistics/igniteui-angular';

import {
  AvatarModule,
  ButtonModule,
  ChipModule,
  DialogModule,
  GridModule,
  IconModule,
  LoadingOverlayModule,
  SearchInputModule,
  SwitchModule,
} from '@supy/components';

import { ViewUsersComponent } from './components';

@NgModule({
  declarations: [ViewUsersComponent],
  imports: [
    CommonModule,
    DialogModule,
    IconModule,
    AvatarModule,
    SearchInputModule,
    LoadingOverlayModule,
    ChipModule,
    IgxGridModule,
    SwitchModule,
    GridModule,
    ButtonModule,
  ],
  exports: [ViewUsersComponent],
})
export class ViewUsersModule {}
