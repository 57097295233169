import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output, signal } from '@angular/core';

import { Destroyable, trackByProperty, UserWithBranches, ViewBranch } from '@supy/common';

import { ViewChannel } from '../../../../core';

@Component({
  selector: 'supy-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelsListComponent extends Destroyable {
  @Input() protected readonly user: UserWithBranches;
  @Input() protected readonly selectedBranch: ViewBranch;
  @Input() protected readonly isLoadingChannels: boolean;

  @Input() protected set channels(value: ViewChannel[]) {
    this.#channels.set(value);
    this.channelsSearchValue.set('');
  }

  @Input() protected set branches(value: ViewBranch[]) {
    this.branchesList.set(value);
  }

  @Output() protected readonly branchChanged = new EventEmitter<ViewBranch>();
  @Output() protected readonly channelClicked = new EventEmitter<ViewChannel>();

  protected readonly trackByBranchId = trackByProperty<ViewBranch>('id');
  protected readonly trackByChannelId = trackByProperty<ViewChannel>('id');

  readonly #channels = signal<ViewChannel[]>([]);

  protected readonly branchesList = signal<ViewBranch[]>([]);
  protected readonly channelsSearchValue = signal<string>('');

  protected readonly filteredChannels = computed(() =>
    this.#channels().filter(
      ({ name, displayName }) =>
        name.toLowerCase().includes(this.channelsSearchValue().toLowerCase()) ||
        displayName?.supplier?.toLowerCase()?.includes(this.channelsSearchValue().toLowerCase()),
    ),
  );

  protected onBranchChanged(value: string[]): void {
    this.branchChanged.emit(this.branchesList().find(({ id }) => id === value.at(0)));
  }
}
