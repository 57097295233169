import { PartyInfo, PreferredType, User } from '@supy/common';

import { ChannelExternal, ChannelMetadata } from './channel.model';

export type ChannelId = string;

export enum ChannelState {
  active = 'active',
  deleted = 'deleted',
}

export class ChannelBase {
  readonly id: ChannelId;
  readonly lastOrderNumber: number;
  readonly retailerId: string;
  readonly supplierId: string;
  readonly parties: string[];
  readonly externals?: ChannelExternal[];
  readonly partyInfo?: PartyInfo;
  readonly state: ChannelState;
  readonly metadata?: ChannelMetadata;
  readonly displayName?: { supplier: string };
  preferredType?: PreferredType;

  constructor(channel: ChannelBase) {
    this.id = channel.id;
    this.lastOrderNumber = 0;
    this.retailerId = channel.retailerId;
    this.supplierId = channel.supplierId;
    this.parties = [channel.retailerId, channel.supplierId];
    this.externals = channel.externals;
    this.partyInfo = channel.partyInfo;
    this.state = channel.state;
    this.metadata = channel.metadata;
    this.displayName = channel.displayName;
  }
}
export class Channel extends ChannelBase {
  readonly users: string[];

  constructor(channel: Channel) {
    super(channel);
    this.users = channel.users;
  }
}

export class PopulatedChannel extends ChannelBase {
  readonly users: User[];

  constructor(channel: PopulatedChannel) {
    super(channel);
    this.users = channel.users;
  }
}

export type ViewChannel = PopulatedChannel & {
  readonly name: string;
  readonly logoUrl?: string;
};
